import React from 'react'
import Drawer from 'material-ui/Drawer'
import MenuItem from 'material-ui/MenuItem'
import AppBar from 'material-ui/AppBar'
import ActionHelpOutline from 'material-ui/svg-icons/action/help-outline'
import ActionList from 'material-ui/svg-icons/action/list'
import ActionLockOpen from 'material-ui/svg-icons/action/lock-open'
import ContentSend from 'material-ui/svg-icons/content/send'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import Chip from 'material-ui/Chip'
import { connect } from 'react-redux'
import Flags from '../components/Flags.jsx'
import Billing from '../components/Billing.jsx'

import {
  setAuthenticated,
  setIdentified
} from '../actions/zlick'

class ZlickNav extends React.Component {
  constructor (props) {
    super(props)
    this.state = { open: false }

    // bind context to methods
    this.handleToggle = this.handleToggle.bind(this)
    this.handleClose = this.handleToggle.bind(this)
    this.handleFeedback = this.handleFeedback.bind(this)
    this.handleFaq = this.handleFaq.bind(this)
    this.handleList = this.handleList.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleSubscriptions = this.handleSubscriptions.bind(this)
  }

  handleToggle () {
    this.setState({ open: !this.state.open })
  }

  handleClose () {
    this.setState({ open: false })
  }

  handleFaq () {
    this.handleClose()
    browserHistory.push('/faq')
  }

  handleList () {
    this.handleClose()
    browserHistory.push('/')
  }

  handleFeedback () {
    this.handleClose()
    browserHistory.push('/feedback')
  }

  handleSubscriptions () {
    this.handleClose()
    browserHistory.push('/subscriptions')
  }

  handleLogout () {
    this.props.setAuthenticated(false)

    // show previous "detected" phone in the login screen
    // no need to run SDK identify again
    this.props.setIdentified()
  }

  render () {
    const route = this.props.router.getCurrentLocation()
    let title = ''
    if (route.pathname.indexOf('faq') > -1) {
      title = this.context.t('FAQ')
    } else if (route.pathname.indexOf('feedback') > -1) {
      title = this.context.t('Feedback')
    } else if (route.pathname.indexOf('subscriptions') > -1) {
      title = this.context.t('My Subscripions')
    } else if (route.pathname === '/') {
      title = this.context.t('My Purchases')
    }
    return (
      <div style={{ paddingTop: '64px' }}>
        <AppBar
          className='appBar'
          title={title}
          iconClassNameRight='muidocs-icon-navigation-expand-more'
          onLeftIconButtonClick={this.handleToggle}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
            color: '#000'
          }}

          titleStyle={{ color: '#000' }}
          iconStyleLeft={{ color: '#000' }}
          iconStyleRight={{ color: '#000' }}
        />
        <Drawer
          open={this.state.open}
          docked={false}
          onRequestChange={(open) => this.setState({ open })}
        >
          <div className='zlick-logo'><img height='48' src='/svg/zlick.svg' /></div>
          <Flags />
          <Chip style={{ marginLeft: '16px', marginBottom: '16px' }}>{this.props.phone}</Chip>
          <Billing />
          <MenuItem onClick={this.handleList} leftIcon={<ActionList />}>{this.context.t('My Purchases')}</MenuItem>
          {/* <MenuItem onClick={this.handleSubscriptions} leftIcon={<AVSubscriptions />}>{this.context.t('My Subscriptions')}</MenuItem> */}
          <MenuItem onClick={this.handleFaq} leftIcon={<ActionHelpOutline />}>{this.context.t('FAQ')}</MenuItem>
          <MenuItem onClick={this.handleFeedback} leftIcon={<ContentSend />}>{this.context.t('Feedback')}</MenuItem>
          <MenuItem onClick={this.handleLogout} leftIcon={<ActionLockOpen />}>{this.context.t('Logout')}</MenuItem>
        </Drawer>
      </div>
    )
  }
}

ZlickNav.propTypes = {
  phone: PropTypes.string,
  router: PropTypes.object,
  setIdentified: PropTypes.func,
  setAuthenticated: PropTypes.func
}

ZlickNav.contextTypes = {
  router: PropTypes.object,
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  phone: state.zlick.phone
})

export default connect(mapStateToProps, { setAuthenticated, setIdentified })(ZlickNav)
