import React from 'react'
import Snackbar from 'material-ui/Snackbar'
import ZlickSubscriptionsList from './ZlickSubscriptionsList.jsx'

export default class ZlickSubscriptions extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      phoneNumber: '',
      limit: 100,
      error: {
        open: false,
        message: ''
      }
    }
  }

  handleErrors (state) {
    this.setState({ error: state })
  }

  render () {
    const snackbarStyle = {
      height: 'auto'
    }
    return (
      <div>
        <ZlickSubscriptionsList />
        <Snackbar
          open={this.state.error.open}
          message={this.state.error.message}
          // action="Vaata"
          autoHideDuration={this.state.error.autoHideDuration}
          onActionClick={this.state.error.handleActionClick}
          onRequestClose={this.state.error.handleRequestClose}
          bodyStyle={snackbarStyle}
        />
      </div>
    )
  }
}
