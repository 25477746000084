import { combineReducers } from 'redux'
import { i18nState } from 'redux-i18n'
import transaction from './transaction'
import billing from './billing'
import subscription from './subscription'
import zlick from './zlick'
import feedback from './feedback'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const authPersistConfig = {
  key: 'zlick',
  storage: storage,
  whitelist: ['accessToken', 'refreshToken', 'phone', 'phonePrefix', 'userIdToken']
}

export default combineReducers({
  i18nState,
  zlick: persistReducer(authPersistConfig, zlick),
  transaction,
  billing,
  subscription,
  feedback
})
