import React from 'react'
import { Card, CardMedia, CardText } from 'material-ui/Card'
import ActionDateRange from 'material-ui/svg-icons/action/date-range'
import ActionTurnedNotIn from 'material-ui/svg-icons/action/turned-in-not'
import currencyMap from './common/currencymap'
import PropTypes from 'prop-types'
import moment from 'moment'
import getMetaData from '../actions/transactions'
import { connect } from 'react-redux'

function parseProductData (productData) {
  if (!productData) return
  if (Array.isArray(productData)) {
    const { productName, name } = productData[0] || {}
    return productName || name
  }
  return productData.name
}

function getTitle (transaction, metadata) {
  const { referer, productData } = transaction
  if (referer && metadata && metadata[referer]) {
    return metadata[referer].title
  }
  const parsedTitle = parseProductData(productData)
  if (parsedTitle) {
    return parsedTitle
  }
  return `${transaction.clientName} ${transaction.content}`
}

function getLogo (clientName) {
  const logos = {
    postimees: '/svg/postimees.svg',
    delfi: '/svg/delfi.svg'
  }
  if (Object.keys(logos).indexOf(clientName) > -1) {
    return logos[clientName]
  }
  return '/png/zlick.png'
}

function ZlickContentImage (props) {
  const { clientName, referer } = props.transaction
  let image = '/png/placeholder.png'
  if (props.metadata && referer && props.metadata[referer]) {
    image = props.metadata[referer].image
  }
  return (
    <div className='contentImage'>
      <CardMedia>
        <img src={image} />
      </CardMedia>
      <div className={'clientLogo ' + clientName}><img src={getLogo(clientName)} /></div>
    </div>
  )
}

class ZlickTransaction extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const title = getTitle(this.props.transaction, this.props.metadata)
    const tx = this.props.transaction
    const refunded = tx && tx.history && tx.history.length > 1
    const currency = ` ${currencyMap[tx.currency] || ' €'}`
    const cardStyle = {
      zDepthShadows: 'none'
    }
    return (
      <div className='contentCard' key={tx.uniqueId}>
        <Card containerStyle={cardStyle}>
          <ZlickContentImage {...this.props} />

          <a style={{ textDecoration: 'none' }} href={tx.referer} >
            <h2 className='title'>{title}</h2>
          </a>
          <CardText>
            <div className='foot-items'>
              <div className='foot-item' style={{ width: '30%', display: 'inline-block' }}>
                <ActionDateRange /> {moment(tx.createdAt).format('DD.MM.YYYY')}
              </div>
              <div className='foot-item' style={{ width: '30%', display: 'inline-block' }}>
                <ActionTurnedNotIn /> { (refunded ? -1 : 1) * (tx.amount / 100).toFixed(2) + currency} {refunded ? this.context.t('refunded') : this.context.t('purchased')}
              </div>
              <div className='foot-item' style={{ float: 'right' }}>{tx.clientName}</div>
            </div>
          </CardText >
        </Card>
      </div>
    )
  }
}

ZlickContentImage.propTypes = {
  transaction: PropTypes.object,
  metadata: PropTypes.object
}

ZlickTransaction.propTypes = {
  transaction: PropTypes.object,
  metadata: PropTypes.object
}

ZlickTransaction.contextTypes = {
  t: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  getMetaData: url => dispatch(getMetaData(url))
})

const mapStateToProps = state => ({
  transactions: state.transaction.items,
  metadata: state.transaction.metadata
})

connect(mapStateToProps, mapDispatchToProps)(ZlickContentImage)

export default connect(mapStateToProps, mapDispatchToProps)(ZlickTransaction)
