import React from 'react'
import { Router, Route, browserHistory, IndexRoute } from 'react-router'
// route components
import ZlickApp from '../../ui/layouts/ZlickApp.jsx'
import AuthPage from '../../ui/pages/AuthPage.jsx'
import ZlickTransactions from '../../ui/components/ZlickTransactions.jsx'
import ZlickSubscriptions from '../../ui/components/ZlickSubscriptions.jsx'
import Faq from '../../ui/components/Faq.jsx'
// translations components
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import I18n from 'redux-i18n'
import { translations } from './translations'
import FeedbackComponent from '../../ui/components/Feedback.jsx'
import { PersistGate } from 'redux-persist/integration/react'

export const renderRoutes = () => {
  return <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <I18n translations={translations} initialLang='en' fallbackLang='en'>
        <Router history={browserHistory}>
          <Route path='/' component={ZlickApp}>
            <IndexRoute component={ZlickTransactions} />
            <Route path='/faq' component={Faq} />
            <Route path='/subscriptions' component={ZlickSubscriptions} />
            <Route path='/feedback' component={FeedbackComponent} />
            <Route path='/signin' component={AuthPage} />
          </Route>
        </Router>
      </I18n>
    </PersistGate>
  </Provider>
}
