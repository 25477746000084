import React from 'react'
import { List, ListItem } from 'material-ui/List'
import RaisedButton from 'material-ui/RaisedButton'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import FaqCustomers from './Faq/Customers.jsx'
import { connect } from 'react-redux'

class Faq extends React.Component {
  state = {
    open: false
  };
  handleToggle = () => {
    this.setState({
      open: !this.state.open
    })
  };
  handleNestedListToggle = (item) => {
    this.setState({
      open: item.state.open
    })
  };
  handleFeedback = () => {
    browserHistory.push('/feedback')
  };
  render () {
    const buttonStyle = {
      borderRadius: '80px',
      boxShadow: 0,
      backgroundColor: '#508ee2',
      height: '42px',
      width: '80vw',
      maxWidth: '300px'
    }
    const buttonLabelStyle = {
      textTransform: 'initial',
      fontWeight: '400'
    }
    const buttonWrapStyle = {
      boxShadow: 0
    }

    return (
      <div className='faqList'>
        <List>
          <ListItem
            key='1'
            primaryText={this.context.t('How is the payment?', {}, 'FAQ question 1')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='1.1'>
                {this.context.t('When you click on the "buy " button, Zlick launches a process in the background that tells the content provider that you have paid and you can start using the service. Information about the payment can also be obtained from the mobile operator and will include the purchase amount in your mobile phone bill for the current month. The amount of Zlick payments is shown on the invoice.', {}, 'FAQ answer 1')}
              </div>
            ]}
          />
          <ListItem
            key='2'
            primaryText={this.context.t('How do I see what I paid for?', {}, 'FAQ question 2')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='2.1'>
                {this.context.t('On my.zlick.it page, you will see a detailed overview of your purchases. You need to go the page with the same device you made the purchases.', {}, 'FAQ answer 2')}
              </div>
            ]}
          />

          <ListItem
            key='3'
            primaryText={this.context.t('Can all customers make a purchase?', {}, 'FAQ question 3')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='3.1'>
                {this.context.t('Purchases can be done by  prepaid and post-paid customers of Orange who do not have extra services limited to their mobile operator.', {}, 'FAQ answer 3')}
              </div>
            ]}
          />
          <ListItem
            key='4'
            primaryText={this.context.t('I can no longer buy what to do?', {}, 'FAQ question 4')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='4.1'>
                {this.context.t('Work by error message:', {}, 'FAQ answer 4')}
                <ol>
                  <li>{this.context.t('Additional billing services are prohibited on your Mobile Phone account." - We recommend contacting your mobile operator to specify if paying for additional services is prohibited or you have exceeded the limit of additional services.', {}, 'FAQ answer 4.1')}</li>
                  <li>{this.context.t('Your phone number is denied Zlick billing. Additional information support@zlick.it "- If we see signs of suspicious activity on the client\'s account, we restrict our automatic payment of damages to this phone number. Please contact support@zlick.it for assistance', {}, 'FAQ answer 4.2')}</li>
                  <li>{this.context.t('All other bug reports and any difficulties we are encountering are also welcome at support@zlick.it', {}, 'FAQ answer 4.3')}</li>
                </ol>
              </div>
            ]}
          />
          <ListItem
            key='6'
            primaryText={this.context.t("I do not want to see Zlick's payment button to see more what to do?", {}, 'FAQ question 6')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div key='6.1' className='faqText'>
                {this.context.t('If you would like to restrict payments from your own mobile phone, please email us at support@zlick.it', {}, 'FAQ answer 6')}
              </div>
            ]}
          />

          <ListItem
            key='7'
            primaryText={this.context.t('How can I cancel my payment?', {}, 'FAQ question 7')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={FaqCustomers(this.props, this.context)}
          />

          <ListItem

            key='8'
            primaryText={this.context.t('I have a question about the payment, where and when can I turn to the payment?', {}, 'FAQ question 8')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div key='81' className='faqText'>
                {this.context.t('Please send any questions to: support@zlick.it or +372 505 9229', {}, 'FAQ answer 8')}
              </div>
            ]}
          />

          <ListItem
            key='9'
            primaryText={this.context.t('What time does customer service work and how fast does it fit?', {}, 'FAQ question 9')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='9.1'>
                {this.context.t('Customer service works on working days from 9 to 17 and we will reply within three working days at the latest.', {}, 'FAQ answer 9')}
              </div>
            ]}
          />

          <ListItem
            key='10'
            primaryText={this.context.t('Which of my data is processed, who will get it and what will be done with it?', {}, 'FAQ question 10')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='10.1'>

                {this.context.t('Zlick only sees the cell phone numbers of the customers, the more we can not get any data about you. According to agreements with mobile operators, Zlick can only use these numbers for billing and Zlick will not transmit numbers to a third party.', {}, 'FAQ answer 10')}
              </div>
            ]}
          />

          <ListItem
            key='11'
            primaryText={this.context.t('I have additional questions about Zlick about where to contact me?', {}, 'FAQ question 11')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='11.1'>
                {this.context.t('Excellent! We are waiting for info@zlick.it. More information is available at www.zlick.it https://www.linkedin.com/company/zlick.it', {}, 'FAQ answer 11')}
              </div>
            ]}
          />

          <ListItem
            key='12'
            primaryText={this.context.t('Where are you located?', {}, 'FAQ question 12')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='12.1'>
                {this.context.t('Zlick Ltd is registered in England, London. Our head office is located in Tallinn, Parda 8.', {}, 'FAQ answer 12')}
              </div>
            ]}
          />

          <ListItem
            key='13'
            primaryText={this.context.t('Who are the founders of Zlick?', {}, 'FAQ question 13')}
            initiallyOpen={false}
            primaryTogglesNestedList
            nestedItems={[
              <div className='faqText' key='13.1'>
                Erki Koldits - CEO
              </div>
            ]}
          />
        </List>

        <div style={{ padding: '16px' }}>
          <h3>{this.context.t("Didn't find the answer to your question?", {}, 'FAQ page bottom')}</h3>
          <p style={{ paddingBottom: '20px' }}>{this.context.t('Give feedback or ask for help.', {}, 'FAQ page bottom')}</p>

          <div className='noBorder' style={{ textAlign: 'center' }}>
            <RaisedButton
              onClick={this.handleFeedback}
              label={this.context.t('Send a message', {}, 'FAQ page bottom')} primary
              labelStyle={buttonLabelStyle}
              style={buttonWrapStyle}
              buttonStyle={buttonStyle}
            />
          </div>
        </div>

      </div>
    )
  }
}

Faq.contextTypes = {
  t: PropTypes.func
}

const mapStateToProps = (state) => ({
  currency: state.zlick.currency,
  country: state.zlick.country
})

export default connect(mapStateToProps, {})(Faq)
