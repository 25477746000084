import React from 'react'
import { Card, CardMedia, CardText } from 'material-ui/Card'
import Actions from './transaction/Actions.jsx'
import ActionDateRange from 'material-ui/svg-icons/action/date-range'
import ActionTurnedNotIn from 'material-ui/svg-icons/action/turned-in-not'
import PropTypes from 'prop-types'
import currencyMap from './common/currencymap'
import moment from 'moment'

class ZlickSubscription extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.getLogo = this.getLogo.bind(this)
  }

  async componentDidMount () {}

  getLogo (clientName) {
    const logos = {
      postimees: '/svg/postimees.svg',
      delfi: '/svg/delfi.svg',
      aripaev: '/jpg/aripaev.jpg'
    }

    if (Object.keys(logos).indexOf(clientName) > -1) {
      return logos[clientName]
    }
    return '/png/zlick.png'
  }

  render () {
    let image
    const subscription = this.props.subscription
    const getLogo = this.getLogo
    const currency = ` ${currencyMap[this.props.currency]}`

    if (this.state.meta && this.state.meta.image) {
      image = this.state.meta.image
    }

    return (
      <div>
        <Card className='subscriptionCard'>

          <div className='contentImage'>
            <CardMedia>
              <img src={image || '/png/placeholder.png'} />
            </CardMedia>

            <div className={'clientLogo ' + subscription.clientName}><img src={getLogo(subscription.clientName)} /></div>
          </div>

          <h2 className='title'>{this.context.t('Subscription')}</h2>
          <CardText>
            <div className='foot-items'>
              <div className='foot-item' style={{ width: '30%', display: 'inline-block' }}>
                <ActionDateRange /> {moment(subscription.createdAt).format('DD.MM.YYYY')}
              </div>
              <div className='foot-item' style={{ width: '30%', display: 'inline-block' }}>
                <ActionTurnedNotIn /> {(subscription.transactions[0].amount / 100).toFixed(2) + currency}
              </div>
              <div className='foot-item' style={{ float: 'right' }}>{subscription.clientName}</div>
            </div>
          </CardText >
          <Actions
            transactions={this.props.subscription.transactions}
            currency={this.props.currency}
          />
        </Card>
      </div>
    )
  }
}

ZlickSubscription.propTypes = {
  subscription: PropTypes.array,
  currency: PropTypes.string

}

ZlickSubscription.contextTypes = {
  t: PropTypes.func
}

export default ZlickSubscription
