import {
  ZLICK_GET_USER_BILLING
} from '../actions/transactions/types'

const initialState = {
  lastMonth: 0,
  currentMonth: 0
}

export default function content (state = initialState, action) {
  switch (action.type) {
    case ZLICK_GET_USER_BILLING:
      return {
        ...state,
        ...action.payload
      }
    default:
      return { ...state }
  }
}
