// SDK events
export const INIT_ZLICK = 'INIT_ZLICK'
export const PHONE_DETECTING = 'PHONE_DETECTING'
export const VERIFICATION_REQUIRED = 'VERIFICATION_REQUIRED'
export const PHONE_REQUIRED = 'PHONE_REQUIRED'
export const IDENTIFICATION_SUCCESS = 'IDENTIFICATION_SUCCESS'
export const IDENTIFICATION_ERROR = 'IDENTIFICATION_ERROR'
export const INPUT_ERROR = 'INPUT_ERROR'
export const APICLIENT_AUTHENTICATED = 'APICLIENT_AUTHENTICATED'
export const SET_PHONE_PREFIX = 'SET_PHONE_PREFIX'

// user interactions
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER'
export const SET_PHONE_REQUIRED = 'SET_PHONE_REQUIRED'
export const SMS_AUTH_START = 'SMS_AUTH_START'
export const SET_VERIFICATION_CODE = 'SET_VERIFICATION_CODE'
export const VERIFY_CODE = 'VERIFY_CODE'
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED'
