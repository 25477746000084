export const translations = {
  'et': {
    'Current month': 'Jooksev kuu',
    'Previous month': 'Eelmine kuu',
    'How is the payment?': 'Kuidas toimub maksmine?',
    'When you click on the "buy " button, Zlick launches a process in the background that tells the content provider that you have paid and you can start using the service. Information about the payment can also be obtained from the mobile operator and will include the purchase amount in your mobile phone bill for the current month. The amount of Zlick payments is shown on the invoice.': 'Kui vajutad „ostan artikli” nupule, käivitab Zlick taustal protsessi, mille jooksul antakse sisuteenuse pakkujale teada, et oled tasunud ning saad hakata teenust kasutama. Teostatud makse kohta saab info ka mobiilsideoperaator ning lisab ostu summa sinu jooksva kuu mobiiltelefoni arvele. Arvel on näha Zlick maksete summa, samuti detailne ülevaade kaupmeeste lõikes.',
    'How do I see what I paid for?': 'Kuidas ma näen, mille eest tasunud olen?',
    'On my.zlick.it page, you will see a detailed overview of your purchases. You need to go the page with the same device you made the purchases.': 'Lehel my.zlick.it näed sama seadmega, millega ostu sooritasid, detailset ülevaadet oma ostudest. Hetkel on leht valmimisel, avame selle kasutamiseks 2017. aasta esimestel kuudel.',
    'Can all customers make a purchase?': 'Kas kõik kliendid saavad ostu sooritada?',
    'Purchases can be done by  prepaid and post-paid customers of Orange who do not have extra services limited to their mobile operator.': 'Oste saavad sooritada Tele2, Elisa ja Telia lepingulised ja kõnekaardi kliendid, kellel ei ole lisateenused oma mobiilsideoperaatori juures ära piiratud.',
    'I can no longer buy what to do?': 'Ma ei saa enam osta, mida teha?',
    'Work by error message:': 'Toimi vastavalt veateatele:',
    'Additional billing services are prohibited on your Mobile Phone account." - We recommend contacting your mobile operator to specify if paying for additional services is prohibited or you have exceeded the limit of additional services.': '“Mobiiltelefoni kontol on keelatud lisateenuste arveldused.” - soovitame pöörduda enda mobiilsideoperaatori poole, et täpsustada, kas lisateenuste eest tasumine on keelatud või oled ületanud lisateenuste kuulimiidi.',
    'Your phone number is denied Zlick billing. Additional information support@zlick.it "- If we see signs of suspicious activity on the client\'s account, we restrict our automatic payment of damages to this phone number. Please contact support@zlick.it for assistance': '“Sinu telefoninumbril on keelatud Zlick arveldused. Lisainfo support@zlick.it” - kui näeme kliendi kontol märke kahtlasest tegevusest, piirame kahjude ära hoidmiseks automaatselt maksete tegemise antud telefoninumbriga. Palun pöördu abi saamiseks support@zlick.it',
    'All other bug reports and any difficulties we are encountering are also welcome at support@zlick.it': 'Kõiki muid veateateid ja kasutamisel esinevaid raskusi ootame samuti support@zlick.it',
    'I do not want to see Zlick\'s payment button to see more what to do?': 'Ma ei soovi Zlick makse nuppu rohkem näha, mida tegema pean?',
    'If you would like to restrict payments from your own mobile phone, please email us at support@zlick.it': 'Kui soovid piirata maksete tegemise enda mobiiltelefonilt, palun anna meile oma soovist teada support@zlick.it',
    'How can I cancel my payment?': 'Kuidas saan makset tühistada?',
    'I have a question about the payment, where and when can I turn to the payment?': 'Mul on makse kohta küsimusi, kuhu ja millal saan pöörduda?',
    'Please send any questions to: support@zlick.it or +372 505 9229': 'Kõik küsimused palun saata: support@zlick.it või 5059229',
    'What time does customer service work and how fast does it fit?': 'Mis ajal klienditeenindus töötab ja kui kiiresti vastab?',
    'Customer service works on working days from 9 to 17 and we will reply within three working days at the latest.': 'Klienditeenindus töötab tööpäevadel kell 9-17 ja vastame hiljemalt kolme tööpäeva jooksul.',
    'Which of my data is processed, who will get it and what will be done with it?': 'Milliseid minu andmeid töödeldakse, kelle kätte need satuvad ja mida nendega tehakse?',
    'Zlick only sees the cell phone numbers of the customers, the more we can not get any data about you. According to agreements with mobile operators, Zlick can only use these numbers for billing and Zlick will not transmit numbers to a third party.': 'Zlick näeb ainult klientide mobiiltelefoni numbreid, rohkem me sinu kohta mingeid andmeid ei saa. Mobiilsideoperaatoritega sõlmitud lepingute kohaselt tohib Zlick neid numbreid kasutada ainult arvelduseks ja Zlick ei edasta numbreid kolmandale osapoolele.',
    'I have additional questions about Zlick about where to contact me?': 'Mul on Zlick teenuse kohta täiendavaid küsimusi, kuhu pöörduda saan?',
    'Excellent! We are waiting for info@zlick.it. More information is available at www.zlick.it https://www.linkedin.com/company/zlick.it': 'Suurepärane! Ootame e-maili info@zlick.it. Rohkem infot on leitav www.zlick.it https://www.linkedin.com/company/zlick.it',
    'Where are you located?': 'Kus te asute?',
    'Zlick Ltd is registered in England, London. Our head office is located in Tallinn, Parda 8.': 'Zlick Ltd on registreeritud Inglismaal, Londonis. Meie peakontor asub Tallinnas, Parda 8.',
    'Who are the founders of Zlick?': 'Kes on Zlick asutajad?',
    'Didn\'t find the answer to your question?': 'Ei leidnud vastust?',
    'Give feedback or ask for help.': 'Anna tagasisidet või palu abi.',
    'Send a message': 'Saada kiri',
    'NK.pl content': 'NK.pl sisu',
    'You can refund your purchase in 30 minutes. After that, the transaction will be confirmed and the only way to get the money back is by contacting support support@zlick.it': 'Sa saad oma raha tagasi küsida 30 minuti jooksul pärast ostu. Pärast seda tehing kinnitatakse ning ainus võimalus on pöörduda klienditeeninduse poole support@zlick.it',
    'Ekspress Grupp publications': 'Ekspress Grupp väljaannetes',
    'After 24 hours you can claim your money back - you can refund one purchase per month. You can request to block the payment service and claim your money back, this option is available at the end of the article. Once you have used your refund in the running month, then you cannot claim back anymore in this month. If you make a purchase after the refund limit has reached, then the system will show you the notice.': '24 tunni jooksul alates makse tegemisest on sul võimalik küsida meilt raha tagasi – saad seda võimalust kasutada maksimaalselt 3 korda kalendrikuu jooksul. Kui soovid teenusest loobuda ja saada raha tagasi, leiad selleks valikuvõimaluse artikli lõpust. Kui oled lubatud 3 korda ära kasutanud, saad endiselt sisu osta, kuid raha selle kalendrikuu jooksul enam tagasi küsida ei saa. Kui teostad ostu peale kolmandat raha tagasi küsimist, kuvab süsteem sulle ka sellekohase hoiatuse.',
    'Eesti Meedia publications': 'Eesti Meedia väljaannetes',
    'After 24 hours you can ask a refund by clicking to the link “Tegin ostu kogemata”. You can refund one purchase per month.': 'Kui tegid ostu kogemata, on sul võimalus tehing tühistada klõpstades lingile “Tegin ostu kogemata”. Seda võimalust saad kasutada 3 korda. Kui oled lubatud 3 korda ära kasutanud, saad endiselt sisu osta, kuid raha selle kalendrikuu jooksul enam tagasi küsida ei saa.',
    'Edasi.org page': 'Edasi.org lehel',
    'After 24 hours you can ask a refund by clicking to the link “Tegin ostu kogemata”. You can refund 3 purchases per month.': 'Kui tegid ostu kogemata, on sul võimalus tehing tühistada klõpstades lingile “Tegin ostu kogemata”. Seda võimalust saad kasutada 3 korda. Kui oled lubatud 3 korda ära kasutanud, siis selle kalendrikuu jooksul enam tagasi küsida ei saa.',
    'Cannot send feedback. Your message is empty': 'Tagasisidet ei saa saata. Sõnum on tühi',
    'Feedback sent': 'Tagasiside saadetud',
    'this field is optional': 'see väli pole kohustuslik',
    'Your e-mail': 'Sinu e-mail',
    'Give feedback or ask for help': 'Anna tagasisidet või küsi abi',
    'Feedback': 'Tagasiside',
    'Send': 'Saada',
    'History': 'Ajalugu',
    'Total items: {n}': 'Kokku: {n}',
    'Purchase': 'Makse',
    'Refund': 'Tagasimakse',
    'Date': 'Kuupäev',
    'Sum': 'Summa',
    'Type': 'Tüüp',
    'FAQ': 'KKK',
    'My Subscripions': 'Minu Tellimused',
    'My Purchases': 'Minu Ostud',
    'My Subscriptions': 'Minu Tellimused',
    'Logout': 'Logi välja',
    'Subscription': 'Tellimus',
    'refunded': 'tagastatud',
    'purchased': 'ostetud',
    'Phone number detected': 'Telefoninumber tuvastatud',
    'Detected number': 'Tuvastatud number',
    'Login': 'Sisene',
    'OR': 'Või',
    'Login with different phone number': 'Sisene muu numbriga',
    'Please log in with SMS': 'Palun logi sisse SMS-iga',
    '+372 5xxx': '+372 5xxx',
    'Please enter your phone number': 'Sisesta telefoni number',
    'Start': 'Alusta',
    'Please enter your SMS code': 'Sisesta saadud kinnituskood',
    'Confirm': 'Kinnita',
    'Detecting your phone number': 'Telefoninumbri tuvastamine',
  },
  'options': {
    'plural_rule': 'n==1 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2',
    'plural_number': '3',
  },
  'pl-PL': {
    'Current month': 'Ten miesiąc',
    'Previous month': 'Poprzedni miesiąc',
    'How is the payment?': 'Jak działa płatność?',
    'When you click on the "buy " button, Zlick launches a process in the background that tells the content provider that you have paid and you can start using the service. Information about the payment can also be obtained from the mobile operator and will include the purchase amount in your mobile phone bill for the current month. The amount of Zlick payments is shown on the invoice.': 'Po zapłaceniu Zlick zaczyna w tle proces powiadomienia dostawcy usługi o Twojej płatności i przyznaniu Ci dostępu do zakupionych treści. Informację o zakupie mogą Państwo odebrać u swojego operatora komórkowego – płatność przy użyciu Zlicka dodana zostanie do rachunku telefonicznego.',
    'How do I see what I paid for?': 'Gdzie mogę przejrzeć swoje płatności?',
    'On my.zlick.it page, you will see a detailed overview of your purchases. You need to go the page with the same device you made the purchases.': 'Na stronie my.zlick.it zobaczą Państwo detale przeprowadzonych przez siebie transakcji. Logowanie powinno nastąpić z tego samego urządzenia, którego użyto do przeprowadzenia transakcji.',
    'Can all customers make a purchase?': 'Czy wszyscy klienci mogą korzystać ze Zlicka?',
    'Purchases can be done by  prepaid and post-paid customers of Orange who do not have extra services limited to their mobile operator.': 'Ze Zlicka korzystać mogą wszyscy klienci Orange, zarówno kart pre-paid jak i abonenci, którzy nie mają limitów na usługi dodatkowe nałożonych na swój numer (mogą one występować np. w przypadku niektórych telefonów służbowych).',
    'I can no longer buy what to do?': 'Nie mogę dalej płacić, w czym tkwi błąd?',
    'Work by error message:': 'W zależności od błędu:',
    'Additional billing services are prohibited on your Mobile Phone account." - We recommend contacting your mobile operator to specify if paying for additional services is prohibited or you have exceeded the limit of additional services.': '„Dodatkowe płatności są zablokowane na Twoim koncie”- skontaktuj się ze swoim operatorem telefonicznym by sprecyzować, czy dana usługa jest zablokowana na Twoim koncie- być może przekroczyłeś też limit na usługi dodatkowe.',
    'Your phone number is denied Zlick billing. Additional information support@zlick.it "- If we see signs of suspicious activity on the client\'s account, we restrict our automatic payment of damages to this phone number. Please contact support@zlick.it for assistance': '„Twój telefon odrzucił płatność Zlickiem. Proszę skontaktować się z support@zlick.it- jeżeli zauważymy znaki podejrzanej aktywności na Twoim koncie automatycznie blokujemy usługi dla Twojego numeru telefonu. Proszę skontaktować się z support@zlick.it w celu dokładnego wyjaśnienia aktywności.',
    'All other bug reports and any difficulties we are encountering are also welcome at support@zlick.it': 'Wszystkie raporty o błędach i inne trudności w płatności prosimy zgłaszać na support@zlick.it',
    'I do not want to see Zlick\'s payment button to see more what to do?': 'Nie chcę widzieć przycisku płatności Zlicka- co powinienem zrobić?',
    'If you would like to restrict payments from your own mobile phone, please email us at support@zlick.it': 'Jeżeli chcesz zablokować opcję płatności z Twojego numeru telefonu proszę skontaktuj sięz nami na support@zlick.it',
    'How can I cancel my payment?': 'Jak mogę wycofać swoją płatność?',
    'I have a question about the payment, where and when can I turn to the payment?': 'Mam pytanie dotyczące płatności, gdzie mogę je zadać?',
    'Please send any questions to: support@zlick.it or +372 505 9229': 'Wszystkie pytania prosimy o wysyłanie na naszą skrzynkę pocztowa support@zlick.it',
    'What time does customer service work and how fast does it fit?': 'W jakich godzinach pracuje customer service?',
    'Customer service works on working days from 9 to 17 and we will reply within three working days at the latest.': 'Nasz customer service pracuje w dni robocze, w godzinach 9-17.',
    'Which of my data is processed, who will get it and what will be done with it?': 'Które moje dane będą przez was procesowane, i jak z nich korzystacie?',
    'Zlick only sees the cell phone numbers of the customers, the more we can not get any data about you. According to agreements with mobile operators, Zlick can only use these numbers for billing and Zlick will not transmit numbers to a third party.': 'Zlick korzysta jedynie z numerów telefonu użytkowników- korzystamy z nich przy przeprowadzić transakcję. Zgodnie z umowami z operatorami telefonicznymi nie możemy korzystać z numerów do jakichkolwiek innych celów, ani przekazywać ich żadnym zewnętrznym podmiotom.',
    'I have additional questions about Zlick about where to contact me?': 'Mam dodatkowe pytania o Zlicku- jak mogę się z wami skontaktować?',
    'Excellent! We are waiting for info@zlick.it. More information is available at www.zlick.it https://www.linkedin.com/company/zlick.it': 'Świetnie! Więcej o Zlicku znajdziesz na naszej stronie internetowej www.zlick.it oraz na naszym profilu na LinkedInie https://www.linkedin.com/company/zlick.it . Jeżeli te źródła nie wyczerpują Twojej ciekawości, zawsze możesz skontaktować się z nami przez naszego maila support@zlick.it',
    'Where are you located?': 'Gdzie jesteście zlokalizowani?',
    'Zlick Ltd is registered in England, London. Our head office is located in Tallinn, Parda 8.': 'Zlick Ltd jest spółką zarejestrowaną w Londynie. Nasze głównie biuro mieści się w Tallinnie przy ul. Parda 8.',
    'Who are the founders of Zlick?': 'Kto założył Zlicka?',
    'Didn\'t find the answer to your question?': 'Nie znalazłeś odpowiedzi na swoje pytanie?',
    'Give feedback or ask for help.': 'Przekaż opinię lub poproś o pomoc.',
    'Send a message': 'Wysłać wiadomość',
    'NK.pl content': 'Treści NK.pl',
    'You can refund your purchase in 30 minutes. After that, the transaction will be confirmed and the only way to get the money back is by contacting support support@zlick.it': 'Możesz poprosić o zwrot w ciągu 30 minut po zakupie. Po tym czasie transakcja zostanie potwierdzona i jedynym sposobem na zwrot będzie kontakt z support@zlick.it',
    'Ekspress Grupp publications': 'Publikacje Ekspress Grupp',
    'After 24 hours you can claim your money back - you can refund one purchase per month. You can request to block the payment service and claim your money back, this option is available at the end of the article. Once you have used your refund in the running month, then you cannot claim back anymore in this month. If you make a purchase after the refund limit has reached, then the system will show you the notice.': 'Po 24 godzinach możesz poprosić o zwrot- możesz zwrócić jeden zakup w miesiącu. Możesz też poprosić o zablokowanie usługi płatności i i zwrot pieniędzy- ta opcja dostępna jest pod koniec artykułu. Możesz zwrócić jedną subskrypcję w miesiącu. Jeżeli zrobisz zakup po przekroczeniu limitu, system powiadomi Cię odpowiednią notyfikacją.',
    'Eesti Meedia publications': 'Publikacje Eesti Media',
    'After 24 hours you can ask a refund by clicking to the link “Tegin ostu kogemata”. You can refund one purchase per month.': 'Po 24 godzinach możesz spytać o zwrot klikając link "Tegin ostu kogemata". Możesz zwrócić 3 zakupy w miesiącu.',
    'Edasi.org page': 'Strona Edasi.org',
    'After 24 hours you can ask a refund by clicking to the link “Tegin ostu kogemata”. You can refund 3 purchases per month.': 'Po 24 godzinach możesz spytać o zwrot klikając link "Tegin ostu kogemata". Możesz zwrócić 3 zakupy w miesiącu.',
    'Cannot send feedback. Your message is empty': 'Nie można znaleźć feedbacku. Twoja wiadomość jest pusta',
    'Feedback sent': 'Feedback wysłany',
    'this field is optional': 'To pole nie jest obowiązkowe',
    'Your e-mail': 'Twój email',
    'Give feedback or ask for help': 'Przekaż opinię lub poproś o pomoc',
    'Feedback': 'Informacje zwrotne',
    'Send': 'Wyślij',
    'History': 'Historia',
    'Total items: {n}': 'Wszystkie artykuły: {n}',
    'Purchase': 'Zakup',
    'Refund': 'Zwrot',
    'Date': 'Data',
    'Sum': 'Suma',
    'Type': 'Typ',
    'FAQ': 'FAQ',
    'My Subscripions': 'Twoje Subskrypcje',
    'My Purchases': 'Twoje Płatności',
    'My Subscriptions': 'Twoje Subskrypcje',
    'Logout': 'Wyloguj się',
    'Subscription': 'Subskrypcja',
    'refunded': 'zwrócone',
    'purchased': 'zakupione',
    'Phone number detected': 'Rozpoznany numer telefonu',
    'Detected number': 'Wykryty numer',
    'Login': 'Zaloguj się',
    'OR': 'LUB',
    'Login with different phone number': 'Zaloguj się przy użyciu innego numeru',
    'Please log in with SMS': 'Proszę zalogować się SMSem',
    '+372 5xxx': '+48xxxxxxxxx',
    'Please enter your phone number': 'Proszę podać numer telefonu',
    'Start': 'Zacznij',
    'Please enter your SMS code': 'Proszę podać kod SMS',
    'Confirm': 'Potwierdź',
    'Detecting your phone number': 'Wykrywanie twojego numeru telefonu',
  },
}
