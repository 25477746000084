import React from 'react'
import ZlickTransactionsList from './ZlickTransactionsList.jsx'
import PropTypes from 'prop-types'
import Snackbar from 'material-ui/Snackbar'

class ZlickTransactions extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      phoneNumber: '',
      limit: 100,

      error: {
        open: false,
        message: ''
      }
    }
  }

  // TODO: use redux for state management
  handleErrors (state) {
    this.setState({ error: state })
  }

  render () {
    const snackbarStyle = {
      height: 'auto'
    }
    return (
      <div>
        <ZlickTransactionsList />
        <Snackbar
          open={this.state.error.open}
          message={this.state.error.message}
          // action="Vaata"
          autoHideDuration={this.state.error.autoHideDuration}
          onActionClick={this.state.error.handleActionClick}
          onRequestClose={this.state.error.handleRequestClose}
          bodyStyle={snackbarStyle}
        />
      </div>
    )
  }
}

ZlickTransactions.contextTypes = {
  t: PropTypes.func
}

export default ZlickTransactions
