import Axios from 'axios'

export const sendSmsPinCode = async ({ accessToken, phone }) => {
  let url = process.env.BASE_URL
  const { data } = await Axios({
    method: 'POST',
    url: url + '/smsauth',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    },
    data: { phone }
  })
  return data
}

export const confirmPinCode = async ({ accessToken, challengeId, pinCode }) => {
  const { data } = await Axios({
    method: 'PUT',
    url: process.env.BASE_URL + '/smsauth',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    },
    data: { challengeId, pinCode }
  })
  return data
}
