import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from 'material-ui/CircularProgress'
import { CardText } from 'material-ui/Card'
import InfiniteScroll from 'react-infinite-scroller';
import ZlickTransaction from './ZlickTransaction.jsx'
import { getTransactions } from '../actions/transactions'
import { connect } from 'react-redux'

function LoaderCentered (props) {
  return (
    <div>
      <CardText style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </CardText>
    </div>
  )
}

class ZlickTransactionsList extends React.Component {
  constructor (props) {
    super(props)
    this.loadMore = this.loadMore.bind(this)
  }

  loadMore (page) {
    this.props.getTransactions({ page })
  }

  render () {
    if (!this.props.ready) {
      return (
        <div style={{ height: '100%' }}>
          <CardText style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </CardText>
        </div>
      )
    }

    if (this.props.transactions.length > 0) {
      return (
        <React.Fragment>
          <InfiniteScroll
            className='infScroll'
            pageStart={1}
            loadMore={this.loadMore}
            hasMore={this.props.currentPage < this.props.pageCount}
            loader={<LoaderCentered key='123' />}
          >
            {this.props.transactions.map(tx =>
              <ZlickTransaction currency={this.props.currency} transaction={tx} key={tx.uniqueId} errorHandler={this.props.errorHandler} />
            )}
          </InfiniteScroll>

        </React.Fragment>
      )
    }
    return (
      <div className='txlist-placeholder'>
        <p>Ostude nimekiri on tühi</p>
        <img src='svg/zlicktx.svg' alt='Tehingud' />
      </div>
    )
  }
}

ZlickTransactionsList.contextTypes = {
  t: PropTypes.func
}

ZlickTransactionsList.propTypes = {
  transactions: PropTypes.array.isRequired,
  ready: PropTypes.bool.isRequired,
  currency: PropTypes.string,
  errorHandler: PropTypes.func,
  getTransactions: PropTypes.func,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number
}

const mapStateToProps = state => ({
  transactions: state.transaction.items,
  ready: state.transaction.ready,
  currency: state.zlick.currency,
  currentPage: state.transaction.currentPage,
  pageCount: state.transaction.pageCount
})

const mapDispatchToProps = dispatch => ({
  getTransactions: ({ page }) => { dispatch(getTransactions({ page })) }
})

export default connect(mapStateToProps, mapDispatchToProps)(ZlickTransactionsList)
