import React from 'react'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'
import Snackbar from 'material-ui/Snackbar';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sendFeedback, startOver, setError } from '../actions/feedback'

class Feedback extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      message: '',
      email: ''
    }
    this.handleFeedback = this.handleFeedback.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this)
  }

  handleChange (_evt, val) {
    this.setState({
      message: val
    })
  }

  handleEmailChange (_evt, val) {
    this.setState({
      email: val
    })
  }

  handleFeedback () {
    if (!this.state.message) {
      return this.props.setError({ error: this.context.t('Cannot send feedback. Your message is empty') })
    }

    this.props.sendFeedback({ message: this.state.message, email: this.state.email })
    this.setState({
      message: '',
      email: ''
    })
  }

  handleSnackbarClose () {
    this.props.startOver()
  }

  render () {
    const buttonStyle = {
      borderRadius: '80px',
      boxShadow: 0,
      backgroundColor: '#508ee2',
      height: '42px',
      width: '80vw',
      maxWidth: '300px'
    }

    const buttonLabelStyle = {
      textTransform: 'initial',
      fontWeight: '400'
    }

    const buttonWrapStyle = {
      boxShadow: 'none'
    }

    return (
      <div>
        <Snackbar
          open={this.props.sent}
          message={this.props.error || this.context.t('Feedback sent')}
          autoHideDuration={4000}
          onRequestClose={this.handleSnackbarClose}
        />

        <div style={{
          paddingLeft: '10px',
          paddingRight: '10px'
        }}>
          <TextField
            hintText={this.context.t('this field is optional')}
            floatingLabelText={this.context.t('Your e-mail')}
            fullWidth
            value={this.state.email}
            onChange={this.handleEmailChange}
            style={{
              marginBottom: '20px'
            }}
          />
        </div>
        <div style={{
          paddingLeft: '10px',
          paddingRight: '10px'
        }}>
          <TextField
            hintText={this.context.t('Give feedback or ask for help')}
            floatingLabelText={this.context.t('Feedback')}
            multiLine
            rows={2}
            fullWidth
            value={this.state.message}
            onChange={this.handleChange}
            style={{
              marginBottom: '20px'
            }}
          />
        </div>
        <div className='noBorder' style={{ textAlign: 'center' }}>
          <RaisedButton
            onClick={this.handleFeedback}
            label={this.context.t('Send')}
            primary
            labelStyle={buttonLabelStyle}
            style={buttonWrapStyle}
            buttonStyle={buttonStyle}
          />
        </div>
      </div>
    )
  }
}

Feedback.contextTypes = {
  t: PropTypes.func
}

Feedback.propTypes = {
  sendFeedback: PropTypes.func,
  startOver: PropTypes.func,
  setError: PropTypes.func,
  error: PropTypes.string,
  sent: PropTypes.bool
}

const mapStateToProps = (state) => ({
  error: state.feedback.error,
  sent: state.feedback.sent
})
export default connect(mapStateToProps, { sendFeedback, startOver, setError })(Feedback)
