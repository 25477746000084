import React from 'react'
import { ListItem } from 'material-ui/List'

const FaqCustomers = (props, context) => {
  // even if the langage is Poslish, but the phone is estonian, then should show estoniean rules
  switch (props.country || 'pl') {
    case 'pl':
      return [<ListItem
        key='7.1'
        primaryText={context.t('NK.pl content')}
        initiallyOpen={false}
        primaryTogglesNestedList
        nestedItems={[
          <div className='faqText'>
            {context.t('You can refund your purchase in 30 minutes. After that, the transaction will be confirmed and the only way to get the money back is by contacting support support@zlick.it', {}, 'Poland only NK.pl answer')}
          </div>
        ]}
      />]

    default:
      return [
        <ListItem
          key='7.1'
          primaryText={context.t('Ekspress Grupp publications')}
          initiallyOpen={false}
          primaryTogglesNestedList
          nestedItems={[
            <div className='faqText'>
              {context.t('After 24 hours you can claim your money back - you can refund one purchase per month. You can request to block the payment service and claim your money back, this option is available at the end of the article. Once you have used your refund in the running month, then you cannot claim back anymore in this month. If you make a purchase after the refund limit has reached, then the system will show you the notice.', {}, 'Estonian only Express Grupp answer')}
            </div>
          ]}
        />,
        <ListItem
          key='7.2'
          primaryText={context.t('Eesti Meedia publications')}
          initiallyOpen={false}
          primaryTogglesNestedList
          nestedItems={[
            <div className='faqText'>
              {context.t('After 24 hours you can ask a refund by clicking to the link “Tegin ostu kogemata”. You can refund one purchase per month.', {}, 'Estonian only Eesti Meedia answer')}
            </div>
          ]}
        />,
        <ListItem
          key='7.3'
          primaryText={context.t('Edasi.org page')}
          initiallyOpen={false}
          primaryTogglesNestedList
          nestedItems={[
            <div className='faqText'>
              {context.t('After 24 hours you can ask a refund by clicking to the link “Tegin ostu kogemata”. You can refund 3 purchases per month.', {}, 'Estonian only Edasi.org page')}
            </div>
          ]}
        />]
  }
}

export default FaqCustomers
