
import React from 'react'
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table'
import currencyMap from '../common/currencymap'
import PropTypes from 'prop-types'
import moment from 'moment'

export default class HistoryTable extends React.Component {
  render () {
    const history = this.props.transactions
    let historyRows = []
    const tableWrapStyle = {
      paddingTop: '1px',
      paddingBottom: '1px'
    }
    const itemType = {
      purchase: this.context.t('Purchase'),
      refund: this.context.t('Refund')
    }
    const currency = ` ${currencyMap[this.props.currency]}`

    history.forEach((item, index) => {
      historyRows.push(
        <TableRow key={index}>
          <TableRowColumn>{moment(item.createdAt).format('DD.MM.YYYY')}</TableRowColumn>
          <TableRowColumn>{item.type === 'refund' ? (-1 * item.amount / 100).toFixed(2) : (item.amount / 100).toFixed(2)}{currency}</TableRowColumn>
          <TableRowColumn>{itemType[item.type]}</TableRowColumn>
        </TableRow>
      )
    })

    return (
      <div style={tableWrapStyle}>
        <Table>
          <TableHeader
            displaySelectAll={false}
          >
            <TableRow>
              <TableHeaderColumn>{this.context.t('Date')}</TableHeaderColumn>
              <TableHeaderColumn>{this.context.t('Sum')}</TableHeaderColumn>
              <TableHeaderColumn>{this.context.t('Type')}</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {historyRows}
          </TableBody>
        </Table>
      </div>
    )
  }
}

HistoryTable.propTypes = {
  currency: PropTypes.string,
  transactions: PropTypes.array
}

HistoryTable.contextTypes = {
  t: PropTypes.func
}
