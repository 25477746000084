import {
  PHONE_DETECTING,
  VERIFICATION_REQUIRED,
  PHONE_REQUIRED,
  IDENTIFICATION_SUCCESS,
  INPUT_ERROR,
  SET_PHONE_NUMBER,
  SMS_AUTH_START,
  SET_PHONE_REQUIRED,
  SET_VERIFICATION_CODE,
  SET_AUTHENTICATED,
  APICLIENT_AUTHENTICATED,
  SET_PHONE_PREFIX
} from '../actions/zlick/types'

const initialState = {
  phonePrefix: '372',
  verificationRequired: false,
  phoneState: PHONE_DETECTING,
  phoneRequired: false,
  phone: '',
  userIdToken: null,
  currency: 'EUR',
  country: 'ee',
  errorMessage: null,
  open: false,
  verificationCode: '',
  jwt: '',
  isAuthenticated: false,
  accessToken: null,
  challengeId: null
}

export default function zlick (state = initialState, action) {
  switch (action.type) {
    case SET_PHONE_PREFIX:
      return {
        ...state,
        phonePrefix: action.payload.phonePrefix
      }
    case APICLIENT_AUTHENTICATED:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      }
    case PHONE_DETECTING:
      return {
        ...state,
        phoneState: action.type,
        jwt: action.payload.token
      }
    case VERIFICATION_REQUIRED:
      return {
        ...state,
        phoneState: action.type,
        verificationRequired: true,
        phoneRequired: false,
        challengeId: action.payload.challengeId
      }
    case PHONE_REQUIRED:
      return {
        ...state,
        phoneState: action.type,
        phoneRequired: true,
        verificationRequired: false
      }
    case IDENTIFICATION_SUCCESS:
      return {
        ...state,
        // don't override previous state if the payload is empty
        // for instace, if user hits logout button, then we already have the initial data to display
        userIdToken: action.payload.userIdToken,
        phoneState: action.type,
        phone: action.payload.phone || state.phone,
        currency: action.payload.currency,
        country: action.payload.country,
        challengeId: null,
        verificationCode: null
      }
    case
      INPUT_ERROR:
      return {
        ...state,
        verificationRequired: false,
        phoneRequired: false,
        open: true,
        errorMessage: action.payload.message
      }

    case SET_PHONE_NUMBER:
      return {
        ...state,
        phone: action.payload
      }

    case SMS_AUTH_START:
      return {
        ...state,
        phone: action.payload
      }

    case SET_PHONE_REQUIRED:
      return {
        ...state,
        phoneRequired: true,
        verificationRequired: false,
        phoneState: PHONE_REQUIRED
      }

    case SET_VERIFICATION_CODE:
      return {
        ...state,
        verificationCode: action.payload
      }

    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload
      }

    default:
      return {
        ...state
      }
  }
};
