import React from 'react'
import Divider from 'material-ui/Divider'
import RaisedButton from 'material-ui/RaisedButton'
import CircularProgress from 'material-ui/CircularProgress'
import { Card, CardText } from 'material-ui/Card'
import TextField from 'material-ui/TextField'
import Snackbar from 'material-ui/Snackbar'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Flags from '../components/Flags.jsx'

import {
  zlick as zlickActions,
  setPhoneNumber,
  setPhoneRequired,
  setAuthenticated,
  smsAuthStart,
  setVerificationCode,
  verifyCode
} from '../actions/zlick'

import { getTransactions } from '../actions/transactions'

import {
  PHONE_DETECTING,
  VERIFICATION_REQUIRED,
  PHONE_REQUIRED
} from '../actions/zlick/types'

class AuthPage extends React.Component {
  constructor (props) {
    super(props)

    const thisContextMethods = [
      'componentDidMount',
      'getDetectedPhoneScreen',
      'handleOtherPhone',
      'handleStartIdentification',
      'handleConfirmCode',
      'onChangePhoneNumber',
      'onChangeVerificationNumber',
      'handleLogin'
    ]
    // bind contexts
    thisContextMethods.forEach(fn => {
      this[fn] = this[fn].bind(this)
    })
  }

  handleLogin () {
    // Session.set('zlick.loggedIn', true);
    this.props.setAuthenticated()
    this.props.getTransactions({ page: 1 })
  }

  handleOtherPhone () {
    // this.setState({ ...this.state, phone: 'phone-required' })
    this.props.setPhoneRequired()
  }
  componentDidMount () {
    this.props.zlickActions({})
  }

  getDetectedPhoneScreen () {
    const buttonStyle = {
      borderRadius: '80px',
      boxShadow: 0,
      backgroundColor: '#508ee2',
      height: '42px',
      width: '80vw',
      maxWidth: '300px'
    }

    const buttonStyleOutline = {
      borderRadius: '80px',
      boxShadow: 0,
      backgroundColor: 'transparent',
      border: '1px solid #508ee2',
      color: '#508ee2',
      height: '42px',
      width: '80vw',
      maxWidth: '300px'
    }

    const buttonLabelStyle = {
      textTransform: 'initial',
      fontWeight: '400'
    }

    const buttonWrapStyle = {
      boxShadow: 0

    }

    return (
      <div className='flex-center' style={{ textAlign: 'center' }}>
        <div className='zlick-head'>
          <img src='/svg/zlick.svg' height='48' />
        </div>
        <p>{this.context.t('Phone number detected', {}, 'Login page')}</p>
        <Flags />
        <Divider inset={false} />
        <p />
        <img src='png/icn-mobile.png' width='75' />
        <h1>+{this.props.phonePrefix} {this.props.phone}</h1>
        <Divider inset={false} />
        <p>{this.context.t('Detected number', {}, 'Login page')}</p>
        <RaisedButton
          labelStyle={buttonLabelStyle}
          style={buttonWrapStyle}
          buttonStyle={buttonStyle}
          onClick={this.handleLogin}
          label={this.context.t('Login', {}, 'Login page')}
          primary
        />
        <p>{this.context.t('OR', {}, 'Login page. Login with detected phone OR login with different')}</p>

        <RaisedButton
          labelStyle={{ color: '#508ee2', ...buttonLabelStyle }}
          style={{ boxShadow: 0 }}
          buttonStyle={buttonStyleOutline}
          onClick={this.handleOtherPhone}
          label={this.context.t('Login with different phone number', {}, 'Login page')}
          primary
        />
      </div>
    )
  }

  onChangePhoneNumber (evt, phone) {
    this.props.setPhoneNumber({ phone })
  }

  onChangeVerificationNumber (evt, code) {
    this.props.setVerificationCode({ code })
  }

  handleConfirmCode (evt) {
    this.props.verifyCode({ code: this.props.verificationCode })
  }

  handleStartIdentification (evt) {
    this.props.smsAuthStart({ phone: this.props.phone })
  }

    handleRequestClose = () => {
      this.setState({
        open: false
      })
    };

    getLoginScreen () {
      const buttonStyle = {
        borderRadius: '80px',
        boxShadow: 0,
        backgroundColor: '#508ee2'
      }

      const buttonLabelStyle = {
        textTransform: 'initial'
      }

      const prefixStyle = {
        float: 'left'
      }

      return (
        <div className='flex-center'>
          <div className='zlick-head'>
            <img src='/svg/zlick.svg' height='48' />
            <p>{this.context.t('Please log in with SMS', {}, 'Login page')}</p>
          </div>
          <Flags />
          <span style={prefixStyle}>+{this.props.phonePrefix}</span>
          <TextField
            hintText={this.context.t('+372 5xxx', {}, 'Login page phone number hint. Example +372xxxxx')}
            floatingLabelText={this.context.t('Please enter your phone number', {}, 'Login page')}
            onChange={this.onChangePhoneNumber}
          />

          <br />

          {
            !this.props.verificationRequired ? (
              <RaisedButton
                labelStyle={buttonLabelStyle}
                style={{ boxShadow: 0 }}
                buttonStyle={buttonStyle}
                onClick={this.handleStartIdentification}
                label={this.context.t('Start', {}, 'Login page, start sms auth')}
                primary
              />
            ) : (

              <div>

                <TextField
                  hintText='1234'
                  floatingLabelText={this.context.t('Please enter your SMS code', {}, 'Login page')}
                  onChange={this.onChangeVerificationNumber}
                />
                <br />
                <RaisedButton
                  labelStyle={buttonLabelStyle}
                  style={{ boxShadow: 0 }}
                  buttonStyle={buttonStyle}
                  onClick={this.handleConfirmCode}
                  label={this.context.t('Confirm', {}, 'Login page, sms auth, confirm the code')}
                  primary
                />
              </div>
            )
          }

          <Snackbar
            open={this.props.open || false}
            message={this.props.errorMessage || ''}
            autoHideDuration={4000}
            onRequestClose={this.handleRequestClose}
          />
        </div>
      )
    }

    render () {
      if (this.props.phoneState === PHONE_DETECTING) {
        return (
          <div className='loader-container'>

            <Card style={{ width: '90%', margin: 'auto' }}>
              <CardText style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <CircularProgress />
                <p style={{ paddingLeft: '30px' }}>{this.context.t('Detecting your phone number', {}, 'Login page')}</p>
              </CardText>
            </Card>

          </div>
        )
      } else if ([PHONE_REQUIRED, VERIFICATION_REQUIRED].indexOf(this.props.phoneState) > -1) {
        return this.getLoginScreen()
      } else {
        return this.getDetectedPhoneScreen()
      }
    }
}

AuthPage.contextTypes = {
  router: PropTypes.object,
  t: PropTypes.func
}

AuthPage.propTypes = {
  phonePrefix: PropTypes.string,
  phone: PropTypes.string,
  verificationCode: PropTypes.string,
  phoneState: PropTypes.string,
  errorMessage: PropTypes.string,
  open: PropTypes.bool,
  verificationRequired: PropTypes.bool,
  smsAuthStart: PropTypes.func,
  verifyCode: PropTypes.func,
  setVerificationCode: PropTypes.func,
  setPhoneNumber: PropTypes.func,
  zlickActions: PropTypes.func,
  setAuthenticated: PropTypes.func,
  getTransactions: PropTypes.func,
  setPhoneRequired: PropTypes.func
}

const mapStateToProps = (state) => ({
  phonePrefix: state.zlick.phonePrefix,
  phoneState: state.zlick.phoneState,
  phone: state.zlick.phone,
  currency: state.zlick.currency,
  country: state.zlick.country,
  verificationRequired: state.zlick.verificationRequired,
  phoneRequired: state.zlick.phoneRequired,
  open: state.zlick.open,
  errorMessage: state.zlick.erroeMessage,
  verificationCode: state.zlick.verificationCode
})

const actions = {
  zlickActions,
  setPhoneNumber,
  setPhoneRequired,
  smsAuthStart,
  setVerificationCode,
  setAuthenticated,
  getTransactions,
  verifyCode
}
export default connect(mapStateToProps, actions)(AuthPage)
