
import React from 'react'
import HistoryTable from './HistoryTable.jsx'
import PropTypes from 'prop-types'
import { List, ListItem } from 'material-ui/List'

class Actions extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false
    }
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleOpen () {
    this.setState({ open: true })
  }

  handleClose () {
    this.setState({ open: false })
  }

  render () {
    return (
      <div>
        <List>
          <ListItem
            key={this.props._id}
            initiallyOpen={false}
            primaryTogglesNestedList
            primaryText={this.context.t('History')}
            secondaryText={this.context.t('Total items: {n}', { n: this.props.transactions.length })}
            nestedItems={[
              <ListItem key='1'>
                <HistoryTable currency={this.props.currency} transactions={this.props.transactions} />
              </ListItem>
            ]}
          />
        </List>
      </div>
    )
  }
}

Actions.propTypes = {
  transactions: PropTypes.array,
  currency: PropTypes.string,
  _id: PropTypes.string
}

Actions.contextTypes = {
  t: PropTypes.func
}

export default Actions
