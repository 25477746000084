import React from 'react'
import { browserHistory } from 'react-router'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import ZlickNav from '../components/ZlickNav.jsx'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setLanguageByIp } from '../actions/zlick'

class ZlickApp extends React.Component {
  constructor (props) {
    super(props)
    this.checkAuth = this.checkAuth.bind(this)
  }

  checkAuth () {
    if (!this.props.isAuthenticated && browserHistory.getCurrentLocation().pathname !== '/signin') {
      browserHistory.push('/signin')
    } else if (this.props.isAuthenticated && browserHistory.getCurrentLocation().pathname === '/signin') {
      browserHistory.push('/')
    }
  }

  async componentWillMount () {
    this.props.setLanguageByIp()
    this.checkAuth()
  }

  componentDidUpdate () {
    this.checkAuth()
  }

  render () {
    return (
      <MuiThemeProvider>
        <div>
          {this.props.isAuthenticated
            ? (
              <ZlickNav {...this.props} />
            )
            : ''
          }
          {this.props.children}
        </div>
      </MuiThemeProvider>
    )
  }
}

ZlickApp.contextTypes = {
  t: PropTypes.func
}

let actions = {
  setLanguageByIp
}

export default connect(state => ({
  lang: state.i18nState.lang,
  isAuthenticated: state.zlick.isAuthenticated
}), actions)(ZlickApp)
