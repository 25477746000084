import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../../ui/reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'


let usable = []

let reduxDevTools = false
try {
  reduxDevTools = window && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
} catch (error) { }

if (reduxDevTools) {
  usable.push(reduxDevTools)
}

const persistedReducer = persistReducer({
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['zlick']
}, rootReducer)

const enhancers = [
  applyMiddleware(thunk),
  ...usable
]

export const store = createStore(persistedReducer, compose(...enhancers))
export const persistor = persistStore(store)
