import React from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import ZlickSubscription from './ZlickSubscription'
import PropTypes from 'prop-types'
import { loadSubscriptions } from '../actions/subscription'
import { connect } from 'react-redux'
import { CardText } from 'material-ui/Card'

class ZlickSubscriptionsList extends React.Component {
  constructor (props) {
    super(props)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  componentDidMount () {
    if (this.props.jwt) {
      this.props.loadSubscriptions(this.props.jwt)
    }
  }

  render () {
    let rows = []
    this.props.subscriptions.forEach((sub) => {
      let id = sub._id
      rows.push(<ZlickSubscription currency={this.props.currency} subscription={sub} key={id} errorHandler={this.props.errorHandler} />)
    })

    if (!this.props.ready) {
      return (
        <div style={{ height: '100%' }}>
          <CardText style={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'center' }}>
            <CircularProgress />
          </CardText>
        </div>
      )
    }

    if (rows.length > 0) {
      return (
        <div>
          { rows }
        </div>
      )
    }

    return (
      <div className='txlist-placeholder'>
        <p>Tellimuste nimekiri on tühi</p>
        <img src='svg/zlicktx.svg' alt='Tehingud' />
      </div>
    )
  }
}

ZlickSubscriptionsList.propTypes = {
  subscriptions: PropTypes.array.isRequired,
  currency: PropTypes.string,
  jwt: PropTypes.string,
  ready: PropTypes.bool,
  loadSubscriptions: PropTypes.func,
  errorHandler: PropTypes.func
}

const mapStateToProps = state => ({
  subscriptions: state.subscription.subscriptions,
  ready: state.subscription.ready,
  jwt: state.zlick.jwt,
  currency: state.zlick.currency
})

const mapDispatchToProps = dispatch => ({
  loadSubscriptions: jwt => dispatch(loadSubscriptions(jwt))
})

export default connect(mapStateToProps, mapDispatchToProps)(ZlickSubscriptionsList)
