import {
  ZLICK_GET_TRANSACTIONS,
  ZLICK_TRANSACTION_METADATA
} from '../actions/transactions/types'

const initialState = {
  ready: false,
  items: [],
  currentPage: 1,
  pageCont: 1,
  totalCount: 0,
  metadata: {}
}

export default function content (state = initialState, action) {
  switch (action.type) {
    case ZLICK_GET_TRANSACTIONS:
      return {
        ...state,
        items: [...state.items, ...action.payload.data],
        currentPage: action.payload.currentPage,
        pageCount: action.payload.pageCount,
        totalCount: action.payload.totalCount,
        ready: true
      }
    case ZLICK_TRANSACTION_METADATA:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ...action.payload
        }
      }
    default:
      return { ...state }
  }
}
