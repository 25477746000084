// Transactions subscriptions reducer
// Subscription states that are actually dispatched automatically
import {
  ZLICK_SUBSCRIPTIONS_SUBSCRIPTION_CHANGED,
  ZLICK_SUBSCRIPTIONS_SUBSCRIPTION_READY,
} from '../actions/subscription/types'

const initialState = {
  ready: false,
  subscriptions: [],
  transactionsSubscriptionStopped: false
}

// With the reactive sources, we can access to the data returned by our
// get function inside the action.payload attribute.
export default function content (state = initialState, action) {
  switch (action.type) {
    case ZLICK_SUBSCRIPTIONS_SUBSCRIPTION_READY:
      return {
        ...state,
        ready: action.payload.ready
      }
    case ZLICK_SUBSCRIPTIONS_SUBSCRIPTION_CHANGED:
      return {
        ...state,
        subscriptions: [...action.payload]
      }
    default:
      return { ...state }
  }
}
