import React, { Component } from 'react'
import IconButton from 'material-ui/IconButton'
import ReactCountryFlag from 'react-country-flag'
import { setLanguage } from 'redux-i18n'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setPhonePrefix } from '../actions/zlick'

class Flags extends Component {
  handleButtonClick (lang) {
    this.props.setLanguage(lang)
    if (this.props.phonePrefix && this.props.phone) return
    if (lang === 'et' || lang === 'en') {
      return this.props.setPhonePrefix({ phonePrefix: '372' })
    }
    this.props.setPhonePrefix({ phonePrefix: '48' })
  }
  render () {
    const styles = {
      mediumIcon: {
        width: 32,
        height: 32,
        fontSize: 32,
        borderRadius: '100%',
        border: '2px solid #efefef',
        backgroundSize: 'cover'
      },
      medium: {
        width: 72,
        height: 72,
        padding: 18
      }
    }
    return (
      <div>
        <IconButton onClick={() => { this.handleButtonClick('et') }} style={styles.medium} iconStyle={styles.mediumIcon}><ReactCountryFlag flagProps={styles.mediumIcon} code='EE' svg /></IconButton>
        <IconButton onClick={() => { this.handleButtonClick('en') }} style={styles.medium} iconStyle={styles.mediumIcon}><ReactCountryFlag flagProps={styles.mediumIcon} code='GB' svg /></IconButton>
        <IconButton onClick={() => { this.handleButtonClick('pl-PL') }} style={styles.medium} iconStyle={styles.mediumIcon}><ReactCountryFlag flagProps={styles.mediumIcon} code='PL' svg /></IconButton>
      </div>
    )
  }
}

Flags.propTypes = {
  setLanguage: PropTypes.func,
  setPhonePrefix: PropTypes.func,
  phonePrefix: PropTypes.string,
  phone: PropTypes.string
}

const mapStateToProps = state => ({
  lang: state.i18nState.lang,
  phonePrefix: state.zlick.phonePrefix,
  phone: state.zlick.phone
})

/* const mapDispatchToProps = dispatch => ({
  loadSubscriptions: jwt => dispatch(loadSubscriptions(jwt)),
}); */

export default connect(mapStateToProps, { setLanguage, setPhonePrefix })(Flags)
