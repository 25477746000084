import Axios from 'axios'
import jwtDecode from 'jwt-decode'
import { 
  ZLICK_GET_TRANSACTIONS, 
  ZLICK_GET_TRANSACTIONS_ERROR, 
  ZLICK_TRANSACTION_METADATA,
  ZLICK_GET_USER_BILLING
} from './types'

export const getUserBilling = userId => async (dispatch, getState) => {
  const { accessToken } = getState().zlick
  const { data } = await Axios({
    method: 'GET',
    url: process.env.BASE_URL + '/transactions/get-user-billing',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + accessToken
    },
    params: {
      userId
    }
  })
  return dispatch({
    type: ZLICK_GET_USER_BILLING,
    payload: data
  })
}

export const getMetaData = (urls) => async dispatch => {
  const { data } = await Axios({
    url: process.env.MYZLICK_BASE_URL + '/get-meta',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: { urls }
  })

  return dispatch({
    type: ZLICK_TRANSACTION_METADATA,
    payload: { ...data }
  })
}

export const getTransactions = ({ page = 1 }) => async (dispatch, getState) => {
  try {
    const { accessToken, userIdToken } = getState().zlick
    let userId = null

    if (!accessToken) return

    if (userIdToken) {
      userId = jwtDecode(userIdToken).userId
    }

    const { data } = await Axios({
      method: 'GET',
      url: process.env.BASE_URL + '/transactions/find-transaction-pairs',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
      params: { page, userId }
    })
    dispatch(getMetaData(data.data.map(x => x.referer)))
    dispatch(getUserBilling(userId))
    return dispatch({
      type: ZLICK_GET_TRANSACTIONS,
      payload: { ...data, currentPage: page }
    })
  } catch (error) {
    return dispatch({
      type: ZLICK_GET_TRANSACTIONS_ERROR,
      payload: { error: error.message }
    })
  }
}
