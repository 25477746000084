// import { setLanguage } from 'redux-i18n'
import Axios from 'axios'
import uuid from 'uuid'
import { sendSmsPinCode, confirmPinCode } from '../../services/PhoneService'

import {
  // PHONE_DETECTING,
  VERIFICATION_REQUIRED,
  PHONE_REQUIRED,
  IDENTIFICATION_SUCCESS,
  // INPUT_ERROR,
  SET_PHONE_NUMBER,
  SET_PHONE_REQUIRED,
  SMS_AUTH_START,
  SET_VERIFICATION_CODE,
  VERIFY_CODE,
  SET_AUTHENTICATED,
  IDENTIFICATION_ERROR,
  APICLIENT_AUTHENTICATED,
  INPUT_ERROR,
  SET_PHONE_PREFIX
} from './types'
import { setLanguage } from 'redux-i18n'

function formatPhone ({ phone, phonePrefix }) {
  if (phone.indexOf('+') === 0) {
    phone = phone.substring(1)
  }
  if (phone.indexOf('00') === 0) {
    phone = phone.substring(2)
  }
  if (phone.indexOf('48') === 0) {
    phonePrefix = '48'
    phone = phone.substring(2)
  }
  if (phone.indexOf('372') === 0) {
    phonePrefix = '372'
    phone = phone.substring(3)
  }
  return {
    phone,
    phonePrefix
  }
}

export const authenticate = () => async dispatch => {
  try {
    const { data } = await Axios({
      method: 'POST',
      url: '/authenticate',
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    })
    return dispatch({
      type: APICLIENT_AUTHENTICATED,
      payload: data
    })
  } catch (error) {
    return dispatch({
      type: IDENTIFICATION_ERROR,
      payload: { error: error.message }
    })
  }
}

export const identifyUser = ({ userIdToken, accessToken, instanceId }) => async (dispatch, getState) => {
  try {
    if (!accessToken) {
      return dispatch({
        type: IDENTIFICATION_ERROR,
        payload: { error: 'Input parameters missing' }
      })
    }
    if (!instanceId) instanceId = uuid()
    const body = { instanceId }
    if (userIdToken) {
      body.userIdToken = userIdToken
    }
    const { data } = await Axios({
      method: 'POST',
      url: process.env.BASE_URL + '/identify',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
      data: body
    })

    if (data.smsAuthRequired) {
      return dispatch({
        type: PHONE_REQUIRED,
        payload: data
      })
    }

    if (data.phone) {
      const { phone } = formatPhone({ phone: data.phone, phonePrefix: getState().phonePrefix })
      data.phone = phone || data.phone
      // data.phonePrefix = phonePrefix
    }

    return dispatch({
      type: IDENTIFICATION_SUCCESS,
      payload: data
    })
  } catch (error) {
    return dispatch({
      type: IDENTIFICATION_ERROR,
      payload: { error: error.message }
    })
  }
}

export const zlick = () => async (dispatch, getState) => {
  await dispatch(authenticate())
  dispatch(identifyUser({ userIdToken: getState().zlick.userIdToken, accessToken: getState().zlick.accessToken }))
}

export const setPhoneRequired = () => {
  return {
    type: SET_PHONE_REQUIRED
  }
}

export const setPhoneNumber = ({ phone }) => {
  return {
    type: SET_PHONE_NUMBER,
    payload: phone
  }
}

export const setPhonePrefix = ({ phonePrefix }) => {
  return {
    type: SET_PHONE_PREFIX,
    payload: { phonePrefix }
  }
}

export const smsAuthStart = ({ phone }) => async (dispatch, getState) => {
  let phonePrefix = getState().zlick.phonePrefix
  let formattedPhone = formatPhone({ phone, phonePrefix })
  phone = formattedPhone.phone
  phonePrefix = formattedPhone.phonePrefix
  dispatch(setPhonePrefix({ phonePrefix }))
  dispatch({
    type: SMS_AUTH_START,
    payload: phone
  })
  try {
    const response = await sendSmsPinCode({ phone: `${phonePrefix}${phone}`, accessToken: getState().zlick.accessToken })
    dispatch({
      type: VERIFICATION_REQUIRED,
      payload: response
    })
  } catch (error) {
    dispatch({
      type: IDENTIFICATION_ERROR,
      payload: { error: error.message }
    })
  }
}

export const setVerificationCode = ({ code }) => {
  return {
    type: SET_VERIFICATION_CODE,
    payload: code
  }
}

export const verifyCode = ({ code }) => async (dispatch, getState) => {
  dispatch({
    type: VERIFY_CODE,
    payload: { code }
  })
  try {
    const { challengeId, accessToken } = getState().zlick
    const response = await confirmPinCode({
      accessToken,
      challengeId,
      pinCode: code
    })
    dispatch({
      type: IDENTIFICATION_SUCCESS,
      payload: response
    })
  } catch (error) {
    dispatch({
      type: INPUT_ERROR,
      error: error.message
    })
  }
}

// TODO: write service
export const setLanguageByIp = () => dispatch => {
  dispatch(setLanguage('ee'))
  /* Meteor.callPromise('zlick.getLanguageByIp', {})
    .then(({ language }) => {
      dispatch(setLanguage(language))
    }) */
}

export const setAuthenticated = (authenticated = true) => {
  return {
    type: SET_AUTHENTICATED,
    payload: authenticated
  }
}

export const setIdentified = () => {
  return {
    type: IDENTIFICATION_SUCCESS,
    payload: {}
  }
}
