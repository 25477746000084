// Transactions subscrioptions reducer

// Subscription states that are actually dispatched automatically
import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_ERROR,
  SEND_FEEDBACK_START_OVER
} from '../actions/feedback/types'

const initialState = {
  sent: false,
  error: null
}

export default function content (state = initialState, action) {
  switch (action.type) {
    case SEND_FEEDBACK_START_OVER:
      return {
        ...action.payload
      }
    case SEND_FEEDBACK:
      return {
        ...state,
        sent: action.payload.success
      }
    case SEND_FEEDBACK_ERROR:
      return {
        ...state,
        error: action.payload.error,
        sent: true
      }
    default:
      return { ...state }
  }
}
