import Axios from 'axios'
import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_ERROR,
  SEND_FEEDBACK_START_OVER
} from './types'

export const startOver = () => {
  return {
    type: SEND_FEEDBACK_START_OVER,
    payload: {
      sent: false,
      error: null
    }
  }
}

export const setError = ({ error }) => {
  return {
    type: SEND_FEEDBACK_ERROR,
    payload: {
      sent: false,
      error
    }
  }
}

export const sendFeedback = ({ message, email }) => async (dispatch, getState) => {
  let { phone, userIdToken } = getState().zlick
  try {
    const { data } = await Axios({
      url: process.env.MYZLICK_BASE_URL + '/send-feedback',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { phone, userIdToken, message, email }
    })

    return dispatch({
      type: SEND_FEEDBACK,
      payload: { ...data }
    })
  } catch (error) {
    dispatch({
      type: SEND_FEEDBACK_ERROR,
      payload: { error: error.message }
    })
  }
}
