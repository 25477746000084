import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const currencyMap = {
  EUR: '€',
  PLN: 'zł'
}

class Billing extends React.Component {
  render () {
    const { billing } = this.props
    const currency = currencyMap[billing.currency] || '€'
    return (
      <div style={{ position: 'relative', paddingLeft: 15, paddingRight: 15, marginTop: 15, marginBottom: 15, textAlign: 'center' }}>
        <div style={{ float: 'left' }}>
          <div style={{ fontSize: 10, marginBottom: 5, textTransform: 'uppercase' }}>{this.context.t('Current month')}</div>
          <div style={{ fontSize: 13 }}>{(billing.currentMonth * 0.01).toFixed(2)} {currency}</div>
        </div>

        <div style={{ float: 'right' }}>
          <div style={{ fontSize: 10, marginBottom: 5, textTransform: 'uppercase' }}>{this.context.t('Previous month')}</div>
          <div style={{ fontSize: 13 }}>{(billing.lastMonth * 0.01).toFixed(2)} {currency}</div>
        </div>
        <div style={{ clear: 'both' }} />
      </div>
    )
  }
}

Billing.propTypes = {
  billing: PropTypes.object
}

const mapStateToProps = state => ({
  billing: state.billing
})

Billing.contextTypes = {
  t: PropTypes.func
}

export default connect(mapStateToProps)(Billing)
